import axios from 'axios';
import camelCaseKeys from 'camelcase-keys';
import get from 'lodash/get';
import flatten from 'lodash/flatten';
import sanitizeHtml from 'sanitize-html';

import { create } from '../logger';
import { UnknownError, FormValidationError } from './errors';

const GREENHOUSE_CONFIG = {
  apiToken: process.env.GREENHOUSE_API_TOKEN,
  boardApiToken: process.env.GREENHOUSE_BOARD_API_TOKEN,
};
const GREENHOUSE_BOARD_ID = process.env.GREENHOUSE_BOARD_ID;

const LANGUAGE_INDICATORS = {
  DE: '(m/w/d)',
  EN: '(m/f/d)',
};

const logger = create('GreenhouseApiService');

let instance = null;

export default class GreenhouseApiService {
  constructor() {
    if (!instance) {
      if (
        !GREENHOUSE_CONFIG.apiToken ||
        !GREENHOUSE_CONFIG.boardApiToken ||
        !GREENHOUSE_BOARD_ID
      ) {
        throw new Error(
          'Greenhouse harvest api token and board api token need to be provided.'
        );
      }

      this.harvestAxios = axios.create({
        baseURL: 'https://harvest.greenhouse.io/v1/',
        auth: {
          username: GREENHOUSE_CONFIG.apiToken,
          password: '',
        },
      });

      this.boardsAxios = axios.create({
        baseURL: 'https://boards-api.greenhouse.io/v1/',
        auth: {
          username: GREENHOUSE_CONFIG.boardApiToken,
          password: '',
        },
      });

      instance = this;
    }

    return instance;
  }

  async postApplication(application) {
    const { jobId } = application;
    const url = `boards/${GREENHOUSE_BOARD_ID}/jobs/${jobId}`;

    try {
      await this.boardsAxios.post(url, application);
    } catch (e) {
      const { error } = get(e, 'response.data');

      logger.error(`Submitting job application failed: ${e}`, error);

      if (error === 'Invalid attributes: email') {
        throw new FormValidationError({
          email: 'VALIDATION_INVALID_EMAIL',
        });
      }

      throw new UnknownError();
    }
  }

  async getJobs() {
    const jobs = await this._fetchJobs();
    const jobPosts = await this._fetchJobPosts();

    logger.info(`${jobs.length} jobs, ${jobPosts.length} jobPosts`);

    const extendedJobPosts = jobs.map(job => {
      const { customFields, departments, offices } = job;
      const { location, employmentType, seniorityLevel } = customFields;

      const relatedJobPosts = jobPosts
        .filter(({ jobId }) => job.id === jobId)
        .map(jobPost => {
          const {
            title,
            id: jobPostId,
            firstPublishedAt,
            updatedAt,
            content,
            questions,
          } = jobPost;

          const slug = `${jobPostId}`;

          return {
            jobId: job.id,
            departments: this._transformDepartments(departments),
            organization: this._transformOffices(offices),
            location,
            employmentType: this._transformEmploymentType(employmentType),
            seniorityLevel,
            jobPostId,
            firstPublishedAt: this._transformFirstPublishedAt(firstPublishedAt),
            updatedAt,
            title,
            listTitle: title
              .replace(LANGUAGE_INDICATORS.EN, '')
              .replace(LANGUAGE_INDICATORS.DE, '')
              .trim(),
            content: sanitizeHtml(content),
            lang: title.includes(LANGUAGE_INDICATORS.EN) ? 'EN' : 'DE',
            url: `/careers/${slug}`,
            questions,
            slug,
          };
        });

      return relatedJobPosts.map(jobPost => ({
        ...jobPost,
        relatedJobPosts: relatedJobPosts.map(({ lang, url }) => ({
          lang,
          url,
        })),
        isListed:
          jobPost.lang === 'EN' ||
          !this._containsEnglishJobPost(relatedJobPosts),
      }));
    });

    logger.info(`${extendedJobPosts.length} extended jobPosts`);

    return flatten(extendedJobPosts);
  }

  async getJob(jobPostId) {
    const jobPost = await this._fetchJobPost(jobPostId);
    const job = await this._fetchJob(jobPost.jobId);
    const allJobPosts = await this._fetchJobPostsForJob(job.id);

    logger.info(`job ${job.id}, jobPost ${jobPost.id}`);

    const { customFields, departments, offices } = job;
    const { location, employmentType, seniorityLevel } = customFields;

    const { title, firstPublishedAt, updatedAt, content, questions } = jobPost;

    const extendedJobPost = {
      jobId: job.id,
      departments: this._transformDepartments(departments),
      organization: this._transformOffices(offices),
      location,
      employmentType: this._transformEmploymentType(employmentType),
      seniorityLevel,
      jobPostId: jobPost.id,
      firstPublishedAt: this._transformFirstPublishedAt(firstPublishedAt),
      updatedAt,
      title,
      listTitle: title
        .replace(LANGUAGE_INDICATORS.EN, '')
        .replace(LANGUAGE_INDICATORS.DE, '')
        .trim(),
      content: sanitizeHtml(content),
      lang: this._getLang(title),
      url: this._createUrl(jobPostId),
      questions,
    };

    const relatedJobPosts = allJobPosts
      .filter(({ active, live }) => active && live)
      .map(relatedJobPost => ({
        lang: this._getLang(relatedJobPost.title),
        url: this._createUrl(relatedJobPost.id),
      }));

    return {
      ...extendedJobPost,
      relatedJobPosts,
      isListed:
        extendedJobPost.lang === 'EN' ||
        !this._containsEnglishJobPost(relatedJobPosts),
    };
  }

  _getLang(title) {
    return title.includes(LANGUAGE_INDICATORS.EN) ? 'EN' : 'DE';
  }

  _createUrl(id) {
    return `/careers/${id}`;
  }

  _containsEnglishJobPost(jobPosts) {
    return jobPosts.some(({ lang }) => lang === 'EN');
  }

  async _fetchJobsPage(page = 1) {
    logger.info(`Fetching "jobs" page: ${page}`);
    const { data: jobs, headers } = await this.harvestAxios.get('jobs', {
      params: {
        status: 'open',
        per_page: 500,
        page,
      },
    });

    const nextPage = this._getNextPageIndexFromHeaders(headers);

    return { jobs, nextPage };
  }

  async _fetchJobs() {
    let aggregatedJobs = [];
    let page = 1;

    while (page !== null) {
      const { jobs, nextPage } = await this._fetchJobsPage(page);
      aggregatedJobs = [...aggregatedJobs, ...jobs];
      page = nextPage;
    }

    return camelCaseKeys(aggregatedJobs, { deep: true });
  }

  async _fetchJobPostsPage(page = 1) {
    logger.info(`Fetching "job_posts" page: ${page}`);

    const { data: jobPosts, headers } = await this.harvestAxios.get(
      'job_posts',
      {
        params: {
          per_page: 500,
          active: true,
          live: true,
          page,
        },
      }
    );

    const nextPage = this._getNextPageIndexFromHeaders(headers);

    return { jobPosts, nextPage };
  }

  async _fetchJobPosts() {
    let aggregatedJobPosts = [];
    let page = 1;

    while (page !== null) {
      const { jobPosts, nextPage } = await this._fetchJobPostsPage(page);
      aggregatedJobPosts = [...aggregatedJobPosts, ...jobPosts];
      page = nextPage;
    }

    return camelCaseKeys(aggregatedJobPosts, { deep: true });
  }

  async _retryWithBackoff(url, retries = 9, backoff = 300) {
    try {
      const { data } = await this.harvestAxios.get(url);
      return camelCaseKeys(data, { deep: true });
    } catch (error) {
      if (error.response && error.response.status === 429 && retries > 0) {
        logger.warn(`Rate limit exceeded. Retrying in ${backoff}ms...`);
        await new Promise(resolve => setTimeout(resolve, backoff));
        return this._retryWithBackoff(url, retries - 1, backoff * 2);
      } else {
        logger.error(`Failed to fetch data. Error: ${error.message}`);
        throw error;
      }
    }
  }

  async _fetchData(url, logMessage, id) {
    logger.info(logMessage, id);
    return this._retryWithBackoff(url);
  }

  async _fetchJobPostsForJob(jobId) {
    return this._fetchData(
      `jobs/${jobId}/job_posts`,
      `Fetching "job_posts" for Job`,
      jobId
    );
  }

  async _fetchJob(id) {
    return this._fetchData(`jobs/${id}`, `Fetching "job"`, id);
  }

  async _fetchJobPost(id) {
    return this._fetchData(`job_posts/${id}`, `Fetching "job_post"`, id);
  }
  _getNextPageIndexFromHeaders(headers) {
    const matches = headers.link.match(/page=(?<page>\d+).+rel="next"/);
    return get(matches, 'groups.page', null);
  }

  _transformFirstPublishedAt(firstPublishedAt) {
    return typeof firstPublishedAt === 'string' ? firstPublishedAt : '';
  }

  _transformEmploymentType(employmentType) {
    return employmentType.split(' - ')[0];
  }

  _transformOffices(offices) {
    return offices
      .map(office => office.name)
      .join(', ')
      .includes('Project A')
      ? 'Project A'
      : 'Portfolio';
  }

  _transformDepartments(departments) {
    return departments
      .map(({ name }) => name)
      .join('\n')
      .trim();
  }
}
