import { createClient } from 'contentful';
import LRU from 'lru';
import { create } from '../logger';
import { transformData } from './data-transform';
import { ENV_NAMES } from '@/shared/constants.mjs';

const TWELVE_HOURS = 12 * 60 * 60 * 1000;

const CONTENTFUL_CONFIG = {
  space: process.env.CONTENTFUL_SPACE_ID,
  accessToken: process.env.CONTENTFUL_ACCESS_TOKEN,
  host: process.env.CONTENTFUL_HOST,
};
const CONTENTFUL_ROOT_PAGE_SLUG = process.env.CONTENTFUL_ROOT_PAGE_SLUG;

const logger = create('ContentfulApiService');

let instance = null;

function createResult(items) {
  return {
    items,
    total: items.length,
  };
}

export default class ContentfulApiService {
  constructor() {
    if (!instance) {
      if (!CONTENTFUL_CONFIG.space || !CONTENTFUL_CONFIG.accessToken) {
        throw new Error(
          'Contentful spaceId and the access token need to be provided.'
        );
      }

      const isProd = process.env.ENV_NAME === ENV_NAMES.LIVE;
      const CACHE_CONFIG = isProd
        ? { max: 500, maxAge: TWELVE_HOURS }
        : {
            max: 0,
            maxAge: 0,
          };

      this.client = createClient(CONTENTFUL_CONFIG);
      this.cache = new LRU(CACHE_CONFIG);
      this.rootPageSlug = CONTENTFUL_ROOT_PAGE_SLUG;
      this.isProd = isProd;

      instance = this;
    }

    return instance;
  }

  async getPage(slug) {
    const key = `page.${slug}`;

    let page = this.cache.get(key);
    let rootPage = this.cache.get(`page.${this.rootPageSlug}`);

    if (page && rootPage && this.isProd) {
      logger.debug(`getPage: Cache hit for key "${key}"`);
      return { page, rootPage };
    }

    logger.debug(`Cache miss for "${key}": fetching type "page"`);

    const { items } = await this.client.getEntries({
      content_type: 'page',
      'fields.slug[in]': `${slug},${this.rootPageSlug}`,
      order: 'fields.slug',
      limit: 1000,
      include: 5,
    });

    items.forEach(item => {
      const transformedData = transformData(item, 999);

      this.cache.set(`page.${item.fields.slug}`, transformedData);

      if (transformedData.slug === this.rootPageSlug) {
        rootPage = transformedData;
      } else {
        page = transformedData;
      }
    });

    return { page: page || rootPage, rootPage };
  }

  async getPages() {
    const pages = await this._getCachedEntries({
      key: 'pages',
      contentType: 'page',
      itemStorageKeyGenerator: page => `page.${page.fields.slug}`,
    });

    return pages;
  }

  async getPagesByLocation(pageLocation) {
    const pages = await this._getCachedEntries({
      key: `pages.${pageLocation}`,
      contentType: 'page',
      searchQuery: { 'fields.location[in]': pageLocation },
    });

    return pages;
  }

  async getTeamMembers() {
    const items = await this._getCachedEntries({
      key: `teamMembers`,
      contentType: 'teamMember',
      order: 'fields.headline',
    });

    return createResult(items);
  }

  async getFaqItems(type) {
    const items = await this._getCachedEntries({
      key: `faqItems.${type}`,
      contentType: 'faqItem',
      searchQuery: { 'fields.type[in]': type },
    });

    return createResult(items);
  }

  getVentures() {
    return this._getCachedEntries({
      key: 'ventures',
      contentType: 'venture',
      order: '-fields.dateOfInvestment',
    });
  }

  getContentItems(types) {
    return this._getCachedEntries({
      key: `content.${types.join()}`,
      contentType: 'knowledge', // This is used for multiple purposes now but content type IDs can not be changed in contenful
      order: '-fields.date',
      searchQuery: { 'fields.type[in]': `${types.join()}` },
    });
  }

  async getDownloadElements(type) {
    const results = await this._getCachedEntries({
      key: `download.${type}`,
      contentType: 'download',
      order: 'fields.headline',
      searchQuery: { 'fields.type[in]': type },
    });

    return createResult(results);
  }

  async _getCachedEntries({
    key,
    contentType,
    include = 2,
    order = 'sys.createdAt',
    searchQuery = {},
    itemStorageKeyGenerator = null,
  }) {
    const value = this.cache.get(key);

    if (value && this.isProd) {
      logger.debug(`_getCachedEntries: Cache hit for key "${key}"`);
      return value;
    }

    logger.debug(
      `_getCachedEntries: Cache miss for key "${key}": fetching type "${contentType}"`
    );

    const { items } = await this.client.getEntries({
      content_type: contentType,
      limit: 1000,
      include,
      order,
      ...searchQuery,
    });

    const transformedData = transformData(items, 999);
    this.cache.set(key, transformedData);

    if (itemStorageKeyGenerator) {
      items.forEach(item =>
        this.cache.set(itemStorageKeyGenerator(item), item)
      );
    }

    return transformedData;
  }
}
