import React from 'react';
import { PageShape } from '../shared/shapes';
import { create } from '../services/logger';
import { captureException } from '../services/error-monitor/error-monitor';
import Page from '../components/page/page';
import { getPage } from '@/services/content/content-service';

const logger = create('ErrorPage');

function Error(props) {
  return <Page {...props} />;
}

Error.getInitialProps = async ({ res, err, query }) => {
  logger.info('Prefetch data');

  const CONTENTFUL_ERROR_PAGE_SLUG = process.env.CONTENTFUL_ERROR_PAGE_SLUG;

  const data = await getPage(query.slug || CONTENTFUL_ERROR_PAGE_SLUG);

  // ignore 404s
  if (err && !(res && res.statusCode === 404)) {
    captureException(err);
  }

  return { ...data };
};

Error.propTypes = {
  page: PageShape,
};

export default Error;
