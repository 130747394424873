/**
 * Transforms a job object from Greenhouse into a page content format.
 *
 * This function is designed to reformat a job object fetched directly
 * from Greenhouse to match the structure that Contentful used to return.
 */
export function transformJobToPageContent(job) {
  return {
    contentType: 'job',
    createdAt: job.firstPublishedAt,
    greenhouseData: { ...job },
    id: `job.${job.jobPostId}`,
    title: job.title.trim(),
    updatedAt: job.updatedAt,
  };
}
