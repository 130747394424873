import { transformJobToPageContent } from './job-helper';
import GreenhouseApiService from '@/services/greenhouse/greenhouse-api';
import {
  applyFilterGroups,
  getFilterGroups,
  updateFilterGroups,
} from '@/services/filter/filter-service';
import ContentfulApiService from '@/services/contentful/contentful-api';
import {
  CONTENT_ITEM_TYPES,
  DOWNLOAD_TYPES,
  FAQ_LIST_MAPPING,
  FAQ_TYPES,
  JOBS_FILTER_GROUPS_CONFIG,
  KNOWLEDGE_FILTER_GROUPS_CONFIG,
  TEAM_FILTER_CONFIG,
  VENTURE_FILTER_GROUPS_CONFIG,
} from '@/shared/constants.mjs';

export async function getPageSectionData({ contentType, type }) {
  switch (contentType) {
    default:
      return {};

    case 'list':
      switch (type) {
        case 'Ventures':
          return getVentures();
        case 'Employees':
          return getTeamMembers();
        case 'Knowledge': {
          const CONTENT_ITEMS = [
            CONTENT_ITEM_TYPES.KNOWLEDGE_ITEM,
            CONTENT_ITEM_TYPES.BLOG,
            CONTENT_ITEM_TYPES.PODCAST,
            CONTENT_ITEM_TYPES.VIDEO,
          ];
          return getContentItems(CONTENT_ITEMS);
        }
        case 'Press Releases': {
          return getContentItems([CONTENT_ITEM_TYPES.PRESS_RELEASE], {
            limit: 6,
          });
        }
        case 'Press Releases Overview':
          return getContentItems([CONTENT_ITEM_TYPES.PRESS_RELEASE]);
        case 'Jobs':
          return getJobs();
        case 'How to approach us FAQ':
        case 'What is your investment criteria FAQ':
        case 'Process FAQ':
        case 'Work with Project A FAQ':
        case 'Operational VC FAQ':
        case 'Investment FAQ':
        case 'About Us FAQ':
        case 'Studio FAQ':
          return getFaqItems(FAQ_LIST_MAPPING[type]);
        case DOWNLOAD_TYPES.MEDIA_ASSETS:
          return getDownloadElements(DOWNLOAD_TYPES.MEDIA_ASSETS);
        case DOWNLOAD_TYPES.PRESS_KIT_PARTNERS:
          return getDownloadElements(DOWNLOAD_TYPES.PRESS_KIT_PARTNERS);
      }
      break;
  }
}

async function getJobs() {
  const apiService = new GreenhouseApiService();
  const jobList = await apiService.getJobs();

  const jobs = jobList
    .filter(({ isListed }) => isListed)
    .map(transformJobToPageContent);

  const filters = getFilterGroups({
    filterGroupsConfig: JOBS_FILTER_GROUPS_CONFIG,
    items: jobs,
  });

  const filteredJobs = applyFilterGroups(filters, jobs);
  const updatedFilters = updateFilterGroups(filters, jobs);

  const sortedFilteredJobs = filteredJobs.sort((a, b) =>
    a.title.localeCompare(b.title)
  );

  return {
    items: sortedFilteredJobs,
    initialFilterGroups: updatedFilters,
  };
}

async function getVentures() {
  const apiService = new ContentfulApiService();
  const ventures = await apiService.getVentures();

  const filters = getFilterGroups({
    filterGroupsConfig: VENTURE_FILTER_GROUPS_CONFIG,
    items: ventures,
  });

  const filteredVentures = applyFilterGroups(filters, ventures);
  const updatedFilters = updateFilterGroups(filters, ventures);

  return {
    items: filteredVentures,
    initialFilterGroups: updatedFilters,
  };
}

async function getTeamMembers() {
  const apiService = new ContentfulApiService();
  const { items } = await apiService.getTeamMembers();

  const filters = getFilterGroups({
    filterGroupsConfig: TEAM_FILTER_CONFIG,
    items,
  });

  const filteredTeamMembers = applyFilterGroups(filters, items);
  const updatedFilters = updateFilterGroups(filters, items);

  return {
    items: filteredTeamMembers,
    initialFilterGroups: updatedFilters,
  };
}

async function getContentItems(types, { limit = null } = {}) {
  const validContentTypes = Object.values(CONTENT_ITEM_TYPES);
  const unknownTypes = types.filter(type => !validContentTypes.includes(type));

  if (unknownTypes.length) {
    throw new Error(`No match for knowledge type(s) ${unknownTypes.join()}`);
  }

  const apiService = new ContentfulApiService();
  const knowledgeItems = await apiService.getContentItems(types);

  const filters = getFilterGroups({
    filterGroupsConfig: KNOWLEDGE_FILTER_GROUPS_CONFIG,
    items: knowledgeItems,
  });

  const filteredKnowledgeItems = applyFilterGroups(filters, knowledgeItems);
  const updatedFilters = updateFilterGroups(filters, knowledgeItems);

  return {
    items: limit
      ? filteredKnowledgeItems.slice(0, limit)
      : filteredKnowledgeItems,
    initialFilterGroups: updatedFilters,
  };
}

async function getFaqItems(type) {
  if (!Object.values(FAQ_TYPES).includes(type)) {
    throw new Error(`No match for FAQ type ${type}`);
  }

  const apiService = new ContentfulApiService();
  return apiService.getFaqItems(type);
}

async function getDownloadElements(type) {
  if (!Object.values(DOWNLOAD_TYPES).includes(type)) {
    throw new Error(`No match for download element type ${type}`);
  }

  const apiService = new ContentfulApiService();
  return apiService.getDownloadElements(type);
}
