import Logger, { LogLevels } from 'logplease';

const isServerEnvironment = typeof window === 'undefined';
const IS_PRODUCTION = process.env.NODE_ENV === 'production';
const PREVENT_LOGGING = IS_PRODUCTION && !isServerEnvironment;

Logger.setLogLevel(
  PREVENT_LOGGING
    ? LogLevels.NONE
    : IS_PRODUCTION
    ? LogLevels.INFO
    : LogLevels.DEBUG
);

const createLogger = name => {
  return Logger.create(name, {
    showTimestamp: false,
  });
};

export const create = (name = 'Logger') => {
  return createLogger(name);
};
