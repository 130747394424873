import { transformJobToPageContent } from './job-helper';
import { getPageSectionData } from './page-section-data';
import GreenhouseApiService from '@/services/greenhouse/greenhouse-api';
import ContentfulApiService from '@/services/contentful/contentful-api';
import { PAGE_LOCATIONS } from '@/shared/constants.mjs';

export async function getPage(slug) {
  const apiService = new ContentfulApiService();
  const { rootPage, page } = await apiService.getPage(slug);

  // Fetch additional page content
  page.content = await Promise.all(
    (page.content || []).map(async contentSection => {
      const sectionData = await getPageSectionData(contentSection);
      return {
        ...contentSection,
        ...sectionData,
      };
    })
  );

  return { rootPage, page };
}

export async function getJobPage(slug) {
  const match = slug.match(/(\d+)$/);
  if (!match || match.length < 2) {
    throw new Error(`No match for page ${slug}`);
  }

  const jobId = parseInt(match[1], 10);
  if (isNaN(jobId)) {
    throw new Error(`No match for page ${slug}`);
  }

  const greenhouseApiService = new GreenhouseApiService();
  const job = await greenhouseApiService.getJob(jobId);

  const { rootPage } = await getPage();

  return {
    page: {
      location: PAGE_LOCATIONS.CAREERS,
      contentType: 'page',
      content: [transformJobToPageContent(job)],
      createdAt: job.firstPublishedAt,
      updatedAt: job.updatedAt,
      id: `page.${job.jobPostId}`,
      pageTitle: `Job: ${job.title.trim()}`,
      slug,
    },
    rootPage,
  };
}

export async function getPagesByLocation(pageLocation) {
  if (!Object.values(PAGE_LOCATIONS).includes(pageLocation)) {
    throw new Error(`No match for page Location ${pageLocation}`);
  }

  const apiService = new ContentfulApiService();
  return apiService.getPagesByLocation(pageLocation);
}
